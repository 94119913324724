// api
import { ApiQueries, ApiTypes } from 'src/backend';

enum RoleTypes {
  ROLE_0000 = 'ROLE_0000', // SIMPLE
  ROLE_0100 = 'ROLE_0100', // RESTAURATEUR
  ROLE_0190 = 'ROLE_0190', // SCANNER
  ROLE_0200 = 'ROLE_0200', // CORPORATE
  ROLE_9999 = 'ROLE_9999', // SUPERADMIN
}

export function RoleArray(): string[] {
  return Object.values(RoleTypes);
}

// Funzione per ottenere il ruolo più alto e gli ID
export const getRoleAndIds = async (
  data: string[]
): Promise<{
  role: string;
  locationIds: string[];
  locations: ApiTypes.Location[];
  roles: string[];
}> => {
  let highestRole: string = RoleTypes.ROLE_0000;
  const locationIds: string[] = [];
  const roles: string[] = [];

  data.forEach((item: any) => {
    if (typeof item === 'string' && RoleArray().includes(item)) {
      if (!highestRole || RoleArray().indexOf(item) > RoleArray().indexOf(highestRole)) {
        highestRole = item;
      }
      roles.push(item);
    } else if (typeof item === 'string') {
      locationIds.push(item);
    }
  });

  const tempLocations: ApiTypes.Location[] = [];

  for (const location of locationIds) {
    // eslint-disable-next-line no-await-in-loop
    const getLocation = await ApiQueries.getLocationMinimalApi(location)
      .promise()
      .then((newData: any) => newData)
      .catch(() => null);

    if (getLocation) {
      tempLocations.push(getLocation);
    } else {
      // eslint-disable-next-line no-await-in-loop
      const getCorporate = await ApiQueries.getCorporateMinimalApi(location)
        .promise()
        .then((newData: any) => newData)
        .catch(() => null);

      if (getCorporate) {
        tempLocations.push({
          ...getCorporate,
          status: 'active',
        });
      }
    }
  }

  return { role: highestRole, locationIds, locations: tempLocations, roles };
};

export default RoleTypes;
